import * as React from 'react';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import GlobalStyles from '../styles/globalStyles';
import TopBar from '../components/topBar/topBar';
import FooterLinks from '../components/footerLinks/footerLinks';
import SideButton from '../components/sideButton/sideButton';
import Footer from '../components/footer/footer';
import NavigationBar from '../components/navigationBar/navigationBar';
import * as Styled from '../styles';
import { graphql, navigate } from 'gatsby';
import HomePageTemplate from '../templates/homePageTemplate/homePageTemplate';
import AOS from 'aos';

const RootIndex = ({ location }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [helmetTitle, setHelmetTitle] = useState('');

  const handleHelmetTitle = (title) => {
    setHelmetTitle(title);
  };

  React.useLayoutEffect(() => {
    AOS.init({
      disable: function () {
        const maxWidth = 767;
        return window.innerWidth < maxWidth;
      },
    });
    AOS.refresh();
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  React.useEffect(() => {
    if (!location?.hash && typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
    if (location?.href !== location?.href?.toLowerCase()) {
      window.location.href = location?.href?.toLowerCase();
    }
    if (location?.pathname !== '/') {
      navigate('/404/');
    }
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (typeof window === 'undefined') {
    return (
      <>
        {' '}
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {helmetTitle ? `${helmetTitle} - ` : ''}Burnaby Refinery
          </title>
          <body className={location?.pathname === '/' ? 'home' : ''} />
        </Helmet>
        <GlobalStyles />
        <SideButton scrollPosition={scrollPosition} />
        <TopBar desktop />
        <Styled.Clear />
        <NavigationBar scrollPosition={scrollPosition} />
        <HomePageTemplate handleHelmetTitle={handleHelmetTitle} />
        <FooterLinks whiteBG={location?.pathname === '/'} />
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {helmetTitle ? `${helmetTitle} - ` : ''}Burnaby Refinery
          </title>
          <body className={location?.pathname === '/' ? 'home' : ''} />
        </Helmet>
        <GlobalStyles />
        <SideButton scrollPosition={scrollPosition} />
        <TopBar desktop />
        <Styled.Clear />
        <NavigationBar scrollPosition={scrollPosition} />
        <HomePageTemplate handleHelmetTitle={handleHelmetTitle} />
        <FooterLinks whiteBG={location?.pathname === '/'} />
        <Footer />
      </>
    );
  }
};

export default RootIndex;

export const pageQuery = graphql`
  fragment PageContent on ContentfulContent {
    id
    title
    type
    header
    subText
    elementId
    contentDetails {
      raw
    }
  }
  fragment ContentList on ContentfulContentList {
    id
    title
    type
    header
    subtext
    elementId
    list {
      title
      type
      header
      subText
      elementId
      contentDetails {
        raw
      }
    }
    listDetails {
      raw
    }
  }
  fragment ContentfulListImages on ContentfulListOfImageWithPath {
    id
    type
    title
    listOfImagesWithPath {
      title
      type
      image {
        file {
          url
        }
      }
    }
  }
  fragment TextWithImage on ContentfulTextWithImage {
    id
    title
    type
    image {
      title
      file {
        url
      }
    }
    text {
      text
    }
  }
  fragment FAQContent on ContentfulFaqs {
    id
    title
    type
    header
    elementId
    description
    qna {
      title
      type
      text {
        raw
      }
      buttons {
        raw
      }
    }
  }
  fragment CalenderList on ContentfulCalenderList {
    id
    title
    type
    calenderEventsList {
      title
      type
      header
      calenderEvent {
        date
        textWithPath {
          type
          text
          path
        }
        text {
          raw
        }
        details {
          raw
        }
      }
      multimedia {
        title
        type
        buttons {
          raw
        }
      }
    }
  }
  fragment MultimediaText on ContentfulMultimediaText {
    title
    type
    multimediaText: text {
      raw
    }
  }
  fragment ImagesWithPathList on ContentfulListOfImageWithPath {
    id
    title
    type
    listOfImagesWithPath {
      title
      type
      path
      richText {
        raw
      }
      image {
        file {
          fileName
          url
        }
      }
    }
  }
  fragment ImageWithPath on ContentfulImageWithPath {
    id
    title
    type
    path
    richText {
      raw
    }
    image {
      file {
        url
      }
    }
  }
  fragment ImageContainer on ContentfulImageContainer {
    id
    title
    type
    image {
      file {
        url
      }
    }
    headerWithText {
      headerText
      text {
        text
      }
    }
    assets {
      title
      file {
        fileName
        url
      }
    }
  }
`;
