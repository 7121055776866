import React from 'react';
import * as Styled from './safetyStyle';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { useContentfulAssets } from '../../../hooks/useContentfulAssets';
import AppButton from '../../../components/appButton/appButton';
import { SECTIONS } from '../../../constants';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
} from '../../../utils';

const Safety = ({ data }) => {
  const HomeSafetyContent = data.list.filter((section) => {
    return section.type === SECTIONS.HOMEPAGE.HOMESAFETYCONTENT;
  })[0];
  const homeSafetyPriorities = data.list.filter((section) => {
    return section.type === SECTIONS.HOMEPAGE.HOMESAFETYPRIORITIES;
  })[0];

  const homeSafetyEmergencypreparedness = data.list.filter((section) => {
    return section.type === SECTIONS.HOMEPAGE.HOMESAFETYEMERGENCYPAREDNESS;
  })[0];

  const ImageStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="m-0">{children}</p>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        if (asset) {
          return (
            <Styled.ImageContainer
              src={asset?.node?.file?.url}
              alt={asset?.node?.title}
            />
          );
        }
      },
    },
  };

  const MainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.Paragraph>{children}</Styled.Paragraph>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <AppButton
          buttonText={children}
          href={addTrailingSlash(node?.data?.uri)}
        />
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };

  const ButtonBlueStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.Paragraph>{children}</Styled.Paragraph>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <AppButton
          buttonText={children}
          href={addTrailingSlash(node?.data?.uri)}
          darkblue
        >
        </AppButton>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };

  return (
    <Styled.HomeSafetyContainer>
      <Styled.HomeSafety>
        <Styled.HomeSafetyLeft
          className=" px-0"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          <Styled.ImageCropper>
            {documentToReactComponents(
              JSON.parse(HomeSafetyContent?.contentDetails?.raw),
              ImageStyle
            )}
          </Styled.ImageCropper>
        </Styled.HomeSafetyLeft>

        <Styled.HomeSafetyRight>
          {HomeSafetyContent?.header && (
            <Styled.SafetyTitle
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              {HomeSafetyContent?.header}
            </Styled.SafetyTitle>
          )}
          <Styled.HomeSafetyFeature>
            <Styled.HomeSafetyFeatureLeft
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              {documentToReactComponents(
                JSON.parse(homeSafetyPriorities?.contentDetails?.raw),
                MainStyle
              )}
            </Styled.HomeSafetyFeatureLeft>

            <Styled.HomeSafetyFeatureRight
              data-aos="fade-up"
              data-aos-delay="800"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              {documentToReactComponents(
                JSON.parse(
                  homeSafetyEmergencypreparedness?.contentDetails?.raw
                ),
                ButtonBlueStyle
              )}
            </Styled.HomeSafetyFeatureRight>
          </Styled.HomeSafetyFeature>
        </Styled.HomeSafetyRight>
      </Styled.HomeSafety>
    </Styled.HomeSafetyContainer>
  );
};

export default Safety;
