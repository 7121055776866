import React from 'react';
import * as Styled from './newsAndUpdatesStyle';
import AppButton from '../../../components/appButton/appButton';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { useContentfulAssets } from '../../../hooks/useContentfulAssets';
import moment from 'moment';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
} from '../../../utils';

const NewsAndUpdates = ({ data }) => {
  const month = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];
  const TEXTStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.HomeNewsRight>{children}</Styled.HomeNewsRight>
      ),
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);

        if (asset) {
          return (
            <Styled.Link href={asset?.node?.file?.url}>{children}</Styled.Link>
          );
        }
      },
    },
  };

  const ButtonStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="m-0">{children}</p>,
      [INLINES.HYPERLINK]: (node, children) => (
        <AppButton
          marginRight
          buttonText={children}
          href={addTrailingSlash(node?.data?.uri)}
        />
      ),
    },
  };

  const ButtonBlueStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="m-0">{children}</p>,
      [INLINES.HYPERLINK]: (node, children) => (
        <AppButton
          darkblue
          buttonText={children}
          href={addTrailingSlash(node?.data?.uri)}
        />
      ),
    },
  };

  return (
    <Styled.NewsAndUpdatesContainer className="px-0">
      <Styled.NewsAndUpdatesInnerContainer>
        <Styled.ContainerRow className="g-0">
          <Styled.LeftTextCol
            lg={4}
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <Styled.HomeNewsLeft>{data?.header}</Styled.HomeNewsLeft>
          </Styled.LeftTextCol>
          <Styled.RightTextCol
            lg={8}
            data-aos="fade-left"
            data-aos-delay="200"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            {documentToReactComponents(JSON.parse(data?.text?.raw), TEXTStyle)}
          </Styled.RightTextCol>
        </Styled.ContainerRow>
        <Styled.CalendarRow className="g-0">
          {data?.calenderEvent?.map((item, index) => {
            return (
              <Styled.News key={index} xs={12} md={4}>
                <Styled.Calendar>
                  <Styled.Month>
                    {month[moment(item?.date, 'MM-DD-YYYY').get('month')]}
                  </Styled.Month>
                  <Styled.Day>
                    {(moment(item?.date, 'MM-DD-YYYY').get('date') < 10
                      ? '0'
                      : '') + moment(item?.date, 'MM-DD-YYYY').get('date')}
                  </Styled.Day>
                </Styled.Calendar>
                {item?.textWithPath && (
                  <Styled.Link
                    href={addTrailingSlash(item?.textWithPath?.path)}
                  >
                    {item?.textWithPath?.text}
                  </Styled.Link>
                )}
                {item?.text?.raw &&
                  documentToReactComponents(
                    JSON.parse(item?.text?.raw),
                    TEXTStyle
                  )}
              </Styled.News>
            );
          })}
        </Styled.CalendarRow>
        <Styled.ButtonRow className="g-0">
          <Styled.Button>
            {data?.multimedia?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {index % 2 === 0
                    ? documentToReactComponents(
                        JSON.parse(item?.buttons?.raw),
                        ButtonStyle
                      )
                    : documentToReactComponents(
                        JSON.parse(item?.buttons?.raw),
                        ButtonBlueStyle
                      )}
                </React.Fragment>
              );
            })}
          </Styled.Button>
        </Styled.ButtonRow>
      </Styled.NewsAndUpdatesInnerContainer>
    </Styled.NewsAndUpdatesContainer>
  );
};

export default NewsAndUpdates;
