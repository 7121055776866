import { Row, Container } from 'react-bootstrap';
import styled from 'styled-components';
import AppColors from '../../styles/colors';

export const MainContainer = styled(Container)`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100% !important;
  height: 770px;
  @media (max-width: 1399px) {
    height: 600px;
  }
  @media (max-width: 991px) {
    height: 580px;
  }
  @media (max-width: 767px) {
    height: auto;
    position: relative;
    background-image: linear-gradient(
      to bottom,
      rgb(0, 59, 95) 0,
      rgb(0, 105, 170) 350px
    ) !important;
  }
`;

export const InnerContainer = styled.div`
  background-image: url(${(props) => props.$backgroundImage});
  position: relative;
  display: block;
  height: 770px;
  padding: 0;
  text-align: center;
  background-color: ${AppColors.BackgroundDarkBlue};
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% auto;
  @media (max-width: 1399px) {
    height: 600px;
  }
  @media (max-width: 1199px) {
    background-position: center 63px;
  }
  @media (max-width: 991px) {
    height: 580px;
    background-size: 1050px auto;
  }
  @media (max-width: 767px) {
    height: auto;
    background-image: none !important;
    background-color: transparent !important;
  }
`;

export const HeaderRow = styled(Row)`
  display: block !important;
  position: absolute;
  left: 50%;
  margin-left: -550px !important;
  width: 1100px;
  z-index: 2;
  bottom: 230px;
  text-align: center;
  @media (max-width: 1399px) {
    bottom: 200px;
  }
  @media (max-width: 1199px) {
    width: 1000px;
    bottom: 195px;
    margin-left: -512px !important;
  }
  @media (max-width: 991px) {
    width: 600px;
    bottom: 185px;
    margin-left: -300px !important;
  }
  @media (max-width: 767px) {
    position: static;
    left: 0;
    top: auto;
    width: 100%;
    height: auto;
    margin-left: 0 !important;
    padding: 120px 40px 0 40px !important;
  }
`;

export const Header = styled.h1`
  opacity: 1;
  transform: translateZ(0);
  transition-timing-function: ease;
  transition-duration: 1s;
  transition-property: opacity, transform;
  margin: 0 0 15px 0;
  font-family: 'interstate', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 58px;
  line-height: 60px;
  text-transform: uppercase;
  color: ${AppColors.White};
  padding: 0;
  @media (max-width: 1199px) {
    font-size: 48px;
    line-height: 50px;
  }
  @media (max-width: 991px) {
    font-size: 30px;
    line-height: 35px;
  }
  @media (max-width: 767px) {
    margin: 0 0 25px 0;
  }
`;

export const SubHeader = styled.h2`
  transition-delay: 1s;
  opacity: 1;
  transform: translateZ(0);
  transition-timing-function: ease;
  transition-duration: 1s;
  transition-property: opacity, transform;
  margin: 0;
  font-family: interstate, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  color: ${AppColors.LightGreen};
  padding: 0;
  @media (max-width: 1199px) {
    font-size: 18px;
    line-height: 28px;
  }
  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const CircleBlock = styled.div`
  position: absolute;
  bottom: -35px;
  display: block;
  height: 227px;
  width: 227px;
  background: ${AppColors.DarkGreen};
  z-index: 4;
  border-radius: 113px;
  left: 50%;
  margin-left: -113px;
  text-align: center;
  @media (max-width: 1399px) {
    height: 175px;
    width: 175px;
    margin: 0 auto;
    bottom: 170px;
    position: relative;
    left: 0;
  }

  @media (max-width: 767px) {
    bottom: -50px;
  }
`;

export const InnerDiv = styled.div`
  height: 227px;
  padding: 50px 0 0 0;
  @media (max-width: 1399px) {
    padding: 40px 0 0 0;
  }
`;

export const Header3 = styled.h3`
  margin: 0;
  font-family: interstate, sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
  color: ${AppColors.White};
  font-style: normal;
  padding: 0;
  @media (max-width: 1399px) {
    font-size: 25px;
    line-height: 25px;
  }
`;

export const Header2 = styled.h2`
  margin: 0;
  font-family: interstate, sans-serif;
  font-size: 88px;
  line-height: 88px;
  font-weight: 400;
  font-style: normal;
  color: ${AppColors.White};

  padding: 0;
  @media (max-width: 1399px) {
    font-size: 65px;
    line-height: 65px;
  }
`;
