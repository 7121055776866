import React, { useState, useEffect } from 'react';
import * as Styled from './homeModalStyle';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const HomeModal = ({ data }) => {
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    setShowModal(true);
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  const paragraphStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.Paragrah>{children}</Styled.Paragrah>
      ),
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        if (asset) {
          return (
            <Styled.LinkStyle
              href={
                isExternalUrlHref(asset.node?.file?.uri)
                  ? asset.node?.file?.uri
                  : addTrailingSlash(
                      process.env.GATSBY_DOMAIN_URL + asset.node?.file?.uri
                    )
              }
              target={'_blank'}
            >
              {children}
            </Styled.LinkStyle>
          );
        }
      },
      [BLOCKS.HR]: () => <Styled.HorizontalBreak />,
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  return (
    <Styled.Modal className={`${showModal ? 'show' : 'hide'}`}>
      <Styled.Content>
        {data.header && (
          <Styled.Heading>{data.header}</Styled.Heading>
        )}
        
        {data &&
          documentToReactComponents(
            JSON.parse(data?.contentDetails?.raw),
            paragraphStyle
          )}
        <Styled.Button onClick={closeModal}>OK</Styled.Button>
      </Styled.Content>
    </Styled.Modal>
  );
};

export default HomeModal;
