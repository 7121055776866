import React from 'react';
import AppButton from '../../../components/appButton/appButton';
import * as Styled from './communityStyle';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { useContentfulAssets } from '../../../hooks/useContentfulAssets';
import { SECTIONS } from '../../../constants';
import isExternalUrl, {
  addTrailingSlash,
  isExternalUrlHref,
} from '../../../utils';

const Community = ({ data }) => {
  const homeCommunityHeader = data.list.filter((section) => {
    return section.type === SECTIONS.HOMEPAGE.HOMECOMMUNITYHEADER;
  })[0];
  const homeCommunityContent = data.list.filter((section) => {
    return section.type === SECTIONS.HOMEPAGE.HOMECOMMUNITYCONTENT;
  })[0];
  const homeCommunityInitiatives = data.list.filter((section) => {
    return section.type === SECTIONS.HOMEPAGE.HOMECOMMUNITYINITIATIVES;
  })[0];
  const homeCommunityImages = data.list.filter((section) => {
    return section.type === SECTIONS.HOMEPAGE.HOMECOMMUNITYIMAGES;
  })[0];
  const homeCommunityLearnMore = data.list.filter((section) => {
    return section.type === SECTIONS.HOMEPAGE.HOMECOMMUNITYLEARNMORE;
  })[0];

  const HeaderStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.Text>{children}</Styled.Text>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <AppButton
          buttonText={children}
          href={addTrailingSlash(node?.data?.uri)}
        />
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };

  const ButtonStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="m-0">{children}</p>,
      [INLINES.HYPERLINK]: (node, children) => (
        <AppButton
          buttonText={children}
          href={addTrailingSlash(node?.data?.uri)}
        />
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };

  const ImageStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="m-0">{children}</p>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        if (asset) {
          return (
            <Styled.ImageCol
              xs={4}
              data-aos="fade-up"
              data-aos-delay={delay}
              data-aos-duration="1000"
              data-aos-once="true"
            >
              <Styled.ImageCropper>
                <Styled.ImageContainer
                  src={asset?.node?.file?.url}
                  alt={asset?.node?.title}
                />
              </Styled.ImageCropper>
            </Styled.ImageCol>
          );
        }
      },
    },
  };
  let delay = 0;
  return (
    <Styled.HomeCommunityContainer className="px-0">
      <Styled.InnerContainer>
        <Styled.CommunityTitle
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          {homeCommunityHeader?.header}
        </Styled.CommunityTitle>

        <Styled.CommunityRowText className="g-0">
          <Styled.LeftTextCol md={6}>
            {documentToReactComponents(
              JSON.parse(homeCommunityContent?.contentDetails?.raw),
              HeaderStyle
            )}
          </Styled.LeftTextCol>
          <Styled.RightTextCol md={6}>
            {documentToReactComponents(
              JSON.parse(homeCommunityInitiatives?.contentDetails?.raw),
              HeaderStyle
            )}
          </Styled.RightTextCol>
        </Styled.CommunityRowText>

        <Styled.CommunityRowImages className="g-0">
          {documentToReactComponents(
            JSON.parse(homeCommunityImages?.contentDetails?.raw),
            ImageStyle
          )}
        </Styled.CommunityRowImages>
        <Styled.Button>
          {documentToReactComponents(
            JSON.parse(homeCommunityLearnMore?.contentDetails?.raw),
            ButtonStyle
          )}
        </Styled.Button>
      </Styled.InnerContainer>
    </Styled.HomeCommunityContainer>
  );
};

export default Community;
