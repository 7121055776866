import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import AppColors from '../../../styles/colors';

export const HomeCommunityContainer = styled(Container)`
  background: ${AppColors.White};
  height: 560px;
  max-width: 100% !important;
  @media (max-width: 1199px) {
    background-size: 1450px;
  }
  @media (max-width: 991px) {
    height: auto;
  }
`;

export const InnerContainer = styled.div`
  width: 1700px;
  margin: 0 auto;
  padding: 60px 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  font-family: 'Gotham-Regular', arial, sans-serif;
  font-size: 17px;
  line-height: 32px;
  color: ${AppColors.Black};
  @media (max-width: 1799px) {
    width: 1300px;
  }
  @media (max-width: 1399px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    text-align: center;
    padding: 10%;
  }
`;

export const CommunityRowText = styled(Row)``;

export const CommunityTitle = styled.div`
  margin: 0 0 60px 0;
  padding: 0 25%;
  font-family: 'Gotham-Light', arial, sans-serif;
  font-size: 30px;
  line-height: 52px;
  color: ${AppColors.LightGreen};
  text-align: center;
  @media (max-width: 1799px) {
    padding: 0 22%;
  }
  @media (max-width: 1399px) {
    margin: 0 0 40px 0;
    line-height: 48px;
  }
  @media (max-width: 991px) {
    padding: 0 10%;
  }
  @media (max-width: 767px) {
    padding: 0;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 30px;
  }
`;

export const LeftTextCol = styled(Col)`
  position: relative;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 3.5% 0 12% !important;
  border-right: 1px solid ${AppColors.Silver};
  text-align: center;
  @media (max-width: 1799px) {
    padding: 0 3% 0 12% !important;
  }
  @media (max-width: 1399px) {
    padding: 0 3% 0 8% !important;
  }
  @media (max-width: 991px) {
    padding: 0 3% 0 6% !important;
  }
  @media (max-width: 767px) {
    padding: 0 0 30px 0 !important;
    border-right: none;
    border-bottom: 1px solid ${AppColors.Silver};
  }
`;

export const RightTextCol = styled(Col)`
  position: relative;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 224px 0 3.5% !important;
  text-align: center;
  @media (max-width: 1799px) {
    padding: 0 12% 0 3% !important;
  }
  @media (max-width: 1399px) {
    padding: 0 11% 0 3% !important;
  }
  @media (max-width: 991px) {
    padding: 0 7% 0 3% !important;
  }
  @media (max-width: 767px) {
    padding: 30px 0 0 0 !important;
  }
`;

export const Text = styled.div`
  font-size: 20px;
  color: ${AppColors.colorBlack};
  line-height: 38px;
  margin: 20px 0;
  text-align: center;
  padding: 0;
  @media (max-width: 1799px) {
    font-size: 18px;
    line-height: 34px;
  }
  @media (max-width: 991px) {
    font-size: 15px;
    line-height: 28px;
    margin: 0 0 40px 0;
  }
  @media (max-width: 767px) {
    margin: 0;
  }
`;

export const CommunityRowImages = styled(Row)`
  width: 100% !important;
  padding: 0 140px !important;
  @media (max-width: 1199px) {
    padding: 0 50px !important;
  }
  @media (max-width: 767px) {
    padding: 0 !important;
  }
`;

export const ImageCol = styled(Col)`
  margin: 80px 0 !important;
  position: relative;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  padding: 0 !important;
  @media (max-width: 1799px) {
    margin: 60px 0 !important;
  }
  @media (max-width: 767px) {
    margin: 0 !important;
    padding: 10px !important;
  }
`;

export const ImageCropper = styled.div`
  position: relative;
  width: 353px;
  height: 353px;
  border-radius: 175px;
  overflow: hidden;
  margin: 0 auto;
  @media (max-width: 1799px) {
    width: 275px;
    height: auto;
  }
  @media (max-width: 1399px) {
    width: 250px;
  }
  @media (max-width: 991px) {
    width: 200px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ImageContainer = styled.img`
  border: 0;
  vertical-align: top;
  width: 353px;
  height: 353px;
  @media (max-width: 1799px) {
    width: 275px;
    height: auto;
  }
  @media (max-width: 1399px) {
    width: 250px;
  }
  @media (max-width: 991px) {
    width: 200px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Button = styled.div`
  @media (max-width: 1399px) {
    margin-top: 15px;
  }
  @media (max-width: 767px) {
    margin-top: 45px;
    margin-bottom: 15px;
  }
`;
