import React from 'react';
import * as Styled from './missionStyle';
import AppButton from '../../../components/appButton/appButton';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../../utils';

const Mission = ({ data }) => {
  return (
    <Styled.MissionContainer
      className="px-0"
      $backgroundImageURL={data?.image?.file?.url}
    >
      <Styled.MissionInnerContainer>
        <Styled.MainRow className="g-0">
          <Styled.LeftCol
            xs={12}
            xxl={5}
            className="px-0"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <Styled.MissionDESC>
              {data?.headerWithText?.text?.text}
            </Styled.MissionDESC>
          </Styled.LeftCol>
          <Styled.MiddleCol
            xs={12}
            xxl={4}
            className="px-0"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <Styled.MissionTitle>
              {data?.textWithPaths[0]?.text}
            </Styled.MissionTitle>
          </Styled.MiddleCol>
          <Styled.RightCol
            xs={12}
            xxl={3}
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-duration="1000"
            data-aos-once="true"
          >
            <AppButton
              darkblue={true}
              buttonText={data?.textWithPaths[1]?.text}
              href={addTrailingSlash(data?.textWithPaths[1]?.path)}
            />
          </Styled.RightCol>
        </Styled.MainRow>
      </Styled.MissionInnerContainer>
    </Styled.MissionContainer>
  );
};

export default Mission;
