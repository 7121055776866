import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import AppColors from '../../../styles/colors';

export const MissionContainer = styled(Container)`
  background: url(${(props) => props.$backgroundImageURL});
  background-repeat: no-repeat;
  height: 874px;
  max-width: 100% !important;
  padding: 200px 0 0 0;
  @media (max-width: 991px) {
    height: auto !important;
    padding: 50px 0 0 0;
    background-size: 1700px auto;
  }
  @media (max-width: 767px) {
    padding-top: 0;
    background: none;
  }
`;

export const MissionInnerContainer = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  background: rgba(0, 164, 86, 0.9);
  padding: 75px;
  @media (max-width: 1799px) {
    padding: 55px;
  }
  @media (max-width: 1399px) {
    padding: 40px 70px;
  }
  @media (max-width: 1199px) {
    padding: 40px 40px 10px 40px;
  }
  @media (max-width: 991px) {
    padding-bottom: 75px;
  }
  @media (max-width: 767px) {
    padding: 10%;
  }
`;

export const MainRow = styled(Row)``;

export const LeftCol = styled(Col)`
  position: relative;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const MiddleCol = styled(Col)`
  position: relative;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const RightCol = styled(Col)`
  position: relative;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 85px 0 56px 4% !important;
  margin: 0 0 40px 0;
  @media (max-width: 1399px) {
    padding: 0 !important;
    text-align: center;
  }
`;

export const MissionDESC = styled.div`
  color: ${AppColors.White};
  font-family: 'Gotham-Regular', arial, sans-serif;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  padding: 0px 2.5%;
  margin: 12px 0 0 0;
  @media (max-width: 1399px) {
    line-height: 30px;
    padding-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 26px;
    margin: 0;
  }
`;

export const MissionTitle = styled.div`
  font-family: interstate, sans-serif;
  color: ${AppColors.White};
  font-size: 30px;
  font-weight: 700;
  line-height: 58px;
  text-transform: uppercase;
  text-align: center;
  padding: 0 10%;
  font-style: normal;
  margin: 0;
  @media (max-width: 1799px) {
    font-size: 28px;
    line-height: 48px;
  }
  @media (max-width: 1399px) {
    line-height: 42px;
    margin-bottom: 40px;
  }
  @media (max-width: 991px) {
    font-size: 23px;
    line-height: 35px;
    padding: 0;
  }
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 32px;
    padding: 0 0 35px 0;
    margin-bottom: 0;
  }
`;
