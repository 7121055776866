import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import AppColors from '../../../styles/colors';
import bgCurveDown from './../../../images/bg-curveDown.png';

export const NewsAndUpdatesContainer = styled(Container)`
  background: rgb(233, 233, 233);
  max-width: 100% !important;
  background: url(${bgCurveDown}) no-repeat top center,
    linear-gradient(
      180deg,
      rgba(233, 233, 233, 1) 0%,
      rgba(254, 254, 254, 1) 50%,
      rgba(233, 233, 233, 1) 100%
    );
  background-size: contain;
  height: auto !important;
  overflow-x: hidden;
`;

export const NewsAndUpdatesInnerContainer = styled.div`
  width: 1700px;
  margin: 0 auto;
  padding: 530px 0 120px 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  font-family: 'Gotham-Regular', arial, sans-serif;
  font-size: 17px;
  line-height: 32px;
  color: ${AppColors.Black};
  @media (max-width: 1799px) {
    width: 1300px;
    padding: 380px 0 80px 0;
  }
  @media (max-width: 1399px) {
    width: 100%;
    padding: 330px 0 90px 0;
  }
  @media (max-width: 1199px) {
    padding-top: 400px;
  }
  @media (max-width: 991px) {
    padding: 80px 0;
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 23px;
  }
`;

export const ContainerRow = styled(Row)``;

export const CalendarRow = styled(Row)`
  padding: 0 20% !important;
  @media (max-width: 1799px) {
    padding: 0 5% !important;
  }
  @media (max-width: 767px) {
    padding: 0 !important;
  }
`;

export const ButtonRow = styled(Row)`
  @media (max-width: 767px) {
    width: 100% !important;
  }
`;

export const LeftTextCol = styled(Col)`
  position: relative;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  padding: 0 0 0 18% !important;
  @media (max-width: 1799px) {
    padding: 0 0 0 14% !important;
  }
  @media (max-width: 1399px) {
    padding: 0 0 0 8% !important;
  }
  @media (max-width: 991px) {
    padding: 0 !important;
  }
`;

export const RightTextCol = styled(Col)`
  position: relative;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  padding: 0 18% 0 5% !important;
  @media (max-width: 1830px) {
    padding: 0 14% 0 8% !important;
  }
  @media (max-width: 1399px) {
    padding: 0 10% 0 4% !important;
  }
  @media (max-width: 991px) {
    padding: 0 !important;
  }
  @media (max-width: 767px) {
    padding: 0 10% !important;
  }
`;

export const InnerContainer = styled.div`
  width: 1300px;
  margin: 0 auto;
  padding: 60px 0;
  @media (max-width: 1400px) {
    width: 1200px;
    text-align: center;
  }
  @media (max-width: 1200px) {
    width: 940px;
  }
  @media (max-width: 991px) {
    width: 700px;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 60px 0 100px 0;
  }
`;

export const HomeNewsLeft = styled.div`
  font-family: interstate, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 58px;
  line-height: 62px;
  color: ${AppColors.DarkGreen};
  text-align: center;
  margin: 0;
  padding: 0;
  font-style: normal;
  @media (max-width: 1399px) {
    font-size: 48px;
    line-height: 54px;
  }
  @media (max-width: 991px) {
    padding-bottom: 20px;
  }
`;

export const HomeNewsRight = styled.div`
  font-size: 20px;
  font-family: 'Gotham-Light', arial, sans-serif;
  line-height: 38px;
  margin: 0;
  padding: 0;
  text-align: center;
  @media (max-width: 1399px) {
    font-size: 18px;
    line-height: 34px;
  }
  @media (max-width: 991px) {
    padding: 0 18%;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 26px;
    padding: 0;
  }
`;

export const News = styled(Col)`
  position: relative;
  padding: 0 40px !important;
  margin: 80px 0 !important;
  text-align: center;
  border-right: 1px solid ${AppColors.Silver};
  &:nth-child(3) {
    border: none;
  }
  @media (max-width: 767px) {
    border: none;
    margin: 20px 0 !important;
  }
`;

export const Calendar = styled.div`
  margin: 0 auto 25px auto;
  width: 75px;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 10%);
`;

export const Month = styled.div`
  padding: 3px 0;
  font-family: interstate, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 21px;
  line-height: 21px;
  text-transform: uppercase;
  text-align: center;
  color: ${AppColors.White};
  background: ${AppColors.LightGreen};
`;

export const Day = styled.div`
  padding: 5px 0;
  font-family: interstate, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 47px;
  line-height: 47px;
  text-transform: uppercase;
  text-align: center;
  color: ${AppColors.LightGreen};
  background: ${AppColors.White};
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${AppColors.LightGreen};
  text-align: center;
  font-family: 'Gotham-Regular', arial, sans-serif;
  font-size: 17px;
  line-height: 32px;
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 23px;
  }
`;

export const Button = styled.div`
  display: flex;
  @media (max-width: 767px) {
    display: block;
    width: 80% !important;
    margin: 0 auto;
  }
`;
