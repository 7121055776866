import React from 'react';
import * as Styled from './sideButtonStyle';
import { graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

export const SideButton = () => {
  const contactNumberData = useStaticQuery(graphql`
    query contactNumberDataQuery {
      allContentfulBurnabyRefinery(
        filter: {
          type: { type: { eq: "contactNumber" }, node_locale: { eq: "en-US" } }
        }
      ) {
        edges {
          node {
            id
            name
            title
            slug
            type {
              type
            }
            sections {
              ... on ContentfulContent {
                id
                type
                title
                header
                subText
                contentDetails {
                  raw
                }
              }
            }
          }
        }
      }
    }
  `);
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  React.useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const contactNumberDataRaw =
    contactNumberData?.allContentfulBurnabyRefinery?.edges[0]?.node?.sections[0]
      .contentDetails.raw;

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.AnchorButton $right="0" $bottom="0">
          {children}
        </Styled.AnchorButton>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };

  return (
    <>
      {scrollPosition > 0 &&
        documentToReactComponents(
          JSON.parse(contactNumberDataRaw),
          optionsMainStyle
        )}
    </>
  );
};

export default SideButton;
