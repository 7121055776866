import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import AppColors from '../../../styles/colors';

export const HistoryContainer = styled(Container)`
  margin-top: -1px;
  background: ${AppColors.White};
  max-width: 100% !important;
`;

export const InnerContainer = styled.div`
  width: 1700px;
  margin: 0 auto;
  padding: 30px 0 60px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  @media (max-width: 1799px) {
    width: 1300px;
  }
  @media (max-width: 1399px) {
    width: 1180px;
    padding: 45px 0 60px 0;
  }
  @media (max-width: 1199px) {
    width: 940px;
  }
  @media (max-width: 991px) {
    width: 700px;
    padding: 100px 0 100px 0;
  }
  @media (max-width: 767px) {
    width: 100%;
    text-align: center;
    padding: 50px 40px 80px 40px;
  }
`;

export const HistoryRow = styled(Row)``;

export const HistoryLeftText = styled.div`
  margin: 100px 70px 0 70px;
  padding: 0;
  font-size: 30px;
  line-height: 48px;
  font-family: 'Gotham-Light', arial, sans-serif;
  color: ${AppColors.LightGreen};
  text-align: center;
  @media (max-width: 1799px) {
    padding: 75px 50px 50px 50px;
    margin: 0;
    font-size: 25px;
    line-height: 37px;
  }
  @media (max-width: 1399px) {
    font-size: 22px;
    line-height: 40px;
    padding: 65px 50px 50px 50px;
  }
  @media (max-width: 1199px) {
    font-size: 18px;
    line-height: 34px;
    padding: 55px 50px 0 30px;
  }
  @media (max-width: 991px) {
    font-size: 18px;
    line-height: 30px;
    padding: 20px 10px 20px 0;
  }
  @media (max-width: 767px) {
    padding: 40px 0 20px 0;
  }
`;

export const HeaderCol = styled(Col)`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
`;

export const ImageCol = styled(Col)`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const ImageCropper = styled.div`
  position: relative;
  width: 465px;
  height: auto;
  border-radius: 375px;
  overflow: hidden;
  margin: 0 0 0 auto;
  @media (max-width: 1799px) {
    width: 340px;
  }
  @media (max-width: 1199px) {
    width: 320px;
  }
  @media (max-width: 991px) {
    width: 250px;
  }
`;

export const ImageContainer = styled.img`
  border: 0;
  vertical-align: top;
  display: block;
  margin: 0 auto;
  height: 100%;
  width: 100%;
`;

export const DescriptionNone = styled.p`
  display: none;
`;
