import styled from 'styled-components';
import AppColors from '../../../styles/colors';
import bgCurveDown from './../../../images/bg-curveDown.png';

export const HomeSafetyContainer = styled.div`
  height: 670px;
  width: 100%;
  background: url(${bgCurveDown}) no-repeat top center,
    linear-gradient(
      180deg,
      rgba(233, 233, 233, 1) 0%,
      rgba(254, 254, 254, 1) 50%,
      rgba(233, 233, 233, 1) 100%
    );
  background-size: contain;
  @media (max-width: 1830px) {
    height: auto;
    padding-bottom: 70px;
  }
  @media (max-width: 1399px) {
    padding-bottom: 50px;
  }
  @media (max-width: 991px) {
    padding-bottom: 0;
  }
`;

export const HomeSafety = styled.div`
  width: 1700px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  @media (max-width: 1830px) {
    width: 1300px;
  }
  @media (max-width: 1399px) {
    width: 100%;
  }
`;

export const HomeSafetyLeft = styled.div`
  position: relative;
  flex: 0 45%;
  flex-basis: auto;
  width: 45%;
  flex-grow: 0;
  flex-shrink: 0;
  @media (max-width: 1830px) {
    flex: 0 40%;
    width: 40%;
  }
  @media (max-width: 1399px) {
    flex-basis: 35%;
  }
  @media (max-width: 991px) {
    flex-basis: 100%;
  }
`;

export const ImageCropper = styled.div`
  top: -10px;
  left: 20px;
  width: 745px;
  height: auto;
  position: relative;
  border-radius: 375px;
  overflow: hidden;
  @media (max-width: 1830px) {
    width: 500px;
    left: 0;
    top: 0;
  }
  @media (max-width: 1399px) {
    width: 530px;
    position: absolute;
    left: -100px;
    top: 30px;
  }
  @media (max-width: 1199px) {
    top: -25px;
  }
  @media (max-width: 991px) {
    position: relative;
    left: 0;
    top: -30px;
    margin: 0 auto;
    width: 400px;
  }
  @media (max-width: 767px) {
    text-align: center;
    width: 250px;
  }
`;

export const ImageContainer = styled.img`
  border: 0;
  vertical-align: top;
  @media (max-width: 1830px) {
    width: 500px;
    height: auto;
  }
  @media (max-width: 1399px) {
    width: 530px;
  }
  @media (max-width: 991px) {
    width: 400px;
  }
  @media (max-width: 767px) {
    width: 250px;
  }
`;

export const HomeSafetyRight = styled.div`
  position: relative;
  flex: 0 50%;
  flex-basis: auto;
  width: 50%;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  @media (max-width: 1830px) {
    flex: 0 60%;
    width: 60%;
  }
  @media (max-width: 1399px) {
    flex-basis: 65%;
    padding: 0 80px 0 0;
  }
  @media (max-width: 1199px) {
    flex-basis: 55%;
    padding-left: 100px;
    padding-right: 0;
  }
  @media (max-width: 991px) {
    flex-basis: 100%;
    padding-left: 0;
  }
`;

export const SafetyTitle = styled.h2`
  margin: 115px 0 50px 0;
  font-size: 65px;
  font-family: interstate, sans-serif;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
  color: ${AppColors.DarkGreen};
  text-align: center;
  @media (max-width: 1830px) {
    margin-top: 100px;
  }
  @media (max-width: 1399px) {
    margin: 100px 0 40px 0;
    font-size: 64px;
  }
  @media (max-width: 991px) {
    margin: 40px 0 40px 0;
  }
  @media (max-width: 767px) {
    font-size: 54px;
    line-height: 23px;
  }
`;

export const HomeSafetyFeature = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  @media (max-width: 991px) {
    margin-bottom: 60px;
  }
  @media (max-width: 767px) {
    text-align: center;
  }
`;

export const HomeSafetyFeatureLeft = styled.div`
  position: relative;
  flex: 0 59%;
  flex-basis: auto;
  width: 59%;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  padding: 25px 40px 25px 60px;
  border-right: 1px solid ${AppColors.Silver};
  @media (max-width: 1830px) {
    flex: 0 58%;
    width: 58%;
  }
  @media (max-width: 1399px) {
    padding: 40px 55px;
  }
  @media (max-width: 1199px) {
    flex: 0 100%;
    width: 100%;
    padding: 25px 0 25px 0;
    border-right: 0;
  }
  @media (max-width: 991px) {
    padding: 20px 50px;
  }
  @media (max-width: 767px) {
    flex-basis: 100%;
    padding: 0 10% 10% 10%;
    border-right: none;
    border-bottom: 1px solid ${AppColors.Silver};
  }
`;

export const HomeSafetyFeatureRight = styled.div`
  position: relative;
  flex: 0 41%;
  flex-basis: auto;
  width: 41%;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  padding: 25px 0 25px 40px;
  @media (max-width: 1830px) {
    flex: 0 42%;
    width: 42%;
    padding-right: 20px;
  }
  @media (max-width: 1399px) {
    padding: 40px;
  }
  @media (max-width: 1199px) {
    flex: 0 100%;
    width: 100%;
    padding: 25px 0 25px 0;
  }
  @media (max-width: 991px) {
    padding: 20px 50px;
  }
  @media (max-width: 767px) {
    flex-basis: 100%;
    padding: 10%;
    padding-bottom: 0;
  }
`;

export const Paragraph = styled.p`
  font-size: 16px;
  line-height: 30px;
  color: ${AppColors.colorBlack};
  margin: 0 0 60px 0;
  padding: 0;
  text-align: center;
  font-family: 'Gotham-Regular', arial, sans-serif;
  &:last-of-type {
    margin-bottom: 0 !important;
  }
  @media (max-width: 1399px) {
    font-size: 15px;
    line-height: 28px;
    margin: 0 0 40px 0;
  }
  @media (max-width: 767px) {
    margin: 0 0 30px 0;
  }
`;
