import React from 'react';
import * as Styled from './headerStyle';

const Header = ({ data }) => {
  return (
    <>
      <Styled.MainContainer className="px-0">
        <Styled.InnerContainer $backgroundImage={data?.image?.file?.url}>
          <Styled.HeaderRow>
            <Styled.Header
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              {data?.headerWithText?.headerText}
            </Styled.Header>
            <Styled.SubHeader
              data-aos="fade-up"
              data-aos-delay="1000"
              data-aos-duration="1000"
              data-aos-once="true"
            >
              {data?.headerWithText?.text?.text}
            </Styled.SubHeader>
          </Styled.HeaderRow>
        </Styled.InnerContainer>
        <Styled.CircleBlock>
          <Styled.InnerDiv>
            <Styled.Header3>
              {data?.textWithPaths[0]?.text?.split(' ')[0]}
            </Styled.Header3>
            <Styled.Header2>
              {data?.textWithPaths[0]?.text?.split(' ')[1]}
            </Styled.Header2>
          </Styled.InnerDiv>
        </Styled.CircleBlock>
      </Styled.MainContainer>
    </>
  );
};

export default Header;
