import styled from 'styled-components';

export const Content = styled.div`
    width: 55%;
    height: 60%;
    background-color: #fff;
    padding: 50px;
    border-radius: 8px;
    text-align: center;
    justify-content: center;
    overflow-y: auto;
    @media (max-width: 1699px) {
      width: 70%;
      height: 60%;
    }
    @media (max-width: 1400px) {
      width: 75%;
      height: 65%;
  }
    @media (max-width: 950px) {
      width: 90%;
      height: 65%;
    }
    @media (max-width: 600px) {
      padding: 20px;
      width: 90%;
      height: 70%;
    }
    @media (max-width: 400px) {
      width: 100%;
      height: 70%;
      padding: 15px;
  }
  @media (max-width: 350px) {
    overflow: scroll;
    height: 80%;
    
  }
`;

export const Modal = styled.div`
  &.show {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 126, 156, 0.5);
    border: 1px solid black;
    justify-content: center;
    align-items: center;
    z-index : 10;
  }
  
  &.hide {
    display: none;
  }
`;

export const Heading = styled.h2`
    justify-self: center;
    font-weight: 800;
    @media (max-width: 600px) {
      font-size: 18px;
    }
`;

export const Paragrah = styled.p`
    font-family: interstate,sans-serif;
    font-weight: 400;
    justify-self: center;
    margin-bottom: 5px !important;
    font-size: 15px;
    @media (max-width: 990px) {
      font-size: 15px;
    }
    @media (max-width: 600px) {
      font-size: 12px;
    }
    a {
      text-decoration: none !important;
    }
    @media (max-width: 550px) {
      overflow-y: scroll;
    }
`;

export const Button = styled.button`
    justify-self: center;
    width: 20%;
    background-color: #0069aa;
    font-family: interstate,sans-serif;
    font-weight: 700;
    color: #fff;
    margin-top: 10px;
    :hover {
        background-color: #0d6efd;
      }
    
    @media (min-width: 1800px) {
      margin-top: 40px;
    }
    @media (max-width: 600px) {
      font-size: 18px;
    }
`;

export const LinkStyle = styled.a`
  text-decoration: none;
  color: green;
`;
