import styled from 'styled-components';
import AppColors from '../../styles/colors';

export const AnchorButton = styled.div`
  display: block;
  position: fixed;
  z-index: 999999;
  top: 360px;
  right: ${(props) => props.$right};
  padding: 22px 30px;
  font-family: 'Gotham-Medium', arial, sans-serif;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  background: ${AppColors.Orange};
  transform: rotate(90deg);
  transform-origin: 100% 0;
  transition: all 0.25s ease-out;
  a {
    text-decoration: none;
    color: ${AppColors.White};
    :hover {
      color: ${AppColors.White};
    }
  }

  @media (max-width: 1300px) {
    top: auto;
    transform: none;
    bottom: ${(props) => props.$bottom};
    padding: 15px 20px;
  }
  :hover {
    padding-top: 30px;
    background: ${AppColors.Cerulean};
    @media (max-width: 1300px) {
      padding: 15px 30px 15px 20px;
    }
  }
`;
