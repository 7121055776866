import React from 'react';
import * as Styled from './historyStyle';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { useContentfulAssets } from '../../../hooks/useContentfulAssets';

const History = ({ data }) => {
  const TEXTStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.HistoryLeftText>{children}</Styled.HistoryLeftText>
      ),
    },
  };

  const ImageStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.DescriptionNone>{children}</Styled.DescriptionNone>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        if (asset) {
          return (
            <Styled.ImageContainer
              src={asset?.node?.file?.url}
              alt={asset?.node?.title}
            />
          );
        }
      },
    },
  };

  return (
    <Styled.HistoryContainer className="px-0">
      <Styled.InnerContainer>
        <Styled.HistoryRow className="g-0">
          <Styled.HeaderCol xs={12} md={7} lg={8} className="px-0">
            {documentToReactComponents(
              JSON.parse(data?.contentDetails?.raw),
              TEXTStyle
            )}
          </Styled.HeaderCol>
          <Styled.ImageCol
            xs={12}
            md={5}
            lg={4}
            className="d-none d-md-block px-0"
          >
            <Styled.ImageCropper>
              {documentToReactComponents(
                JSON.parse(data?.contentDetails?.raw),
                ImageStyle
              )}
            </Styled.ImageCropper>
          </Styled.ImageCol>
        </Styled.HistoryRow>
      </Styled.InnerContainer>
    </Styled.HistoryContainer>
  );
};

export default History;
