import styled from 'styled-components';
import bgCurve from '../../images/bg-curveUp.png';

export const BottomBlock = styled.div`
  display: block;
  background: url(${bgCurve}) no-repeat bottom center;
  z-index: 3;
  position: relative;
  height: 104px;
  margin-top: 606px;
  background-size: contain;
  @media (max-width: 1399px) {
    margin-top: 436px;
  }
  @media (max-width: 1199px) {
    height: 85px;
    margin-top: 454px;
  }
  @media (max-width: 991px) {
    margin-top: 435px;
  }
  @media (max-width: 767px) {
    margin-top: -84px;
  }
`;
